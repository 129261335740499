import React from 'react'
import SignIn from '../signin';
import ScrollToTop from '../components/scrolltotop';

const SignInPage = () => {
  return (
    <>
    <ScrollToTop />
    <SignIn />
    </>
  )
};

export default SignInPage;