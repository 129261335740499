import React from 'react'
import Icon1 from '../../img/svg-1.svg'
import Icon2 from '../../img/svg-2.svg'
import Icon3 from '../../img/svg-3.svg'
import { ServicesContainer, 
    ServicesH1,
ServicesWrapper,
ServicesCard, 
ServicesIcon, 
ServicesH2, 
ServicesP } from './serviceelements' 

const Services = () => {
  return (
    <>
    <ServicesContainer id='services'>
        <ServicesH1>Our Services</ServicesH1>
        <ServicesWrapper>
            <ServicesCard>
                <ServicesIcon src={Icon1} />
                <ServicesH2>Reduce Expenses</ServicesH2>
                <ServicesP>We Help Reduce your fees and increase your overall revenue</ServicesP>
            </ServicesCard>
            <ServicesCard>
                <ServicesIcon src={Icon2} />
                <ServicesH2>Reduce Expenses</ServicesH2>
                <ServicesP>We Help Reduce your fees and increase your overall revenue</ServicesP>
            </ServicesCard>
            <ServicesCard>
                <ServicesIcon src={Icon3} />
                <ServicesH2>Reduce Expenses</ServicesH2>
                <ServicesP>We Help Reduce your fees and increase your overall revenue</ServicesP>
            </ServicesCard>
        </ServicesWrapper>
    </ServicesContainer>
    </>
  )
}

export default Services