export const homeObjOne = {
    id: 'about',
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    topLine: 'Premium Bank',
    headline: 'Unlimited Transactions With Zero Fees',
    description: 'Get access to our exclusive app that allows you to send unlimited transactions without getting charged any fees',
    buttonLabel: 'Get Started',
    imgStart: false,
    img: require('../../img/svg-1.svg').default,
    alt: 'Car',
    dark: true,
    primary: true,
    darkText: false
};

export const homeObjTwo = {
    id: 'discover',
    lightBg: true, //background color
    lightText: false, //headline color
    lightTextDesc: false,
    topLine: 'Premium Bank',
    headline: 'Unlimited Transactions With Zero Fees',
    description: 'Get access to our exclusive app that allows you to send unlimited transactions without getting charged any fees',
    buttonLabel: 'Learn More',
    imgStart: true, //photo position true right false left
    img: require('../../img/svg-2.svg').default,
    alt: 'Phone',
    dark: false, //button text color
    primary: false, //button color
    darkText: true //desc color
};

export const homeObjThree = {
    id: 'contactme',
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    topLine: 'Premium Bank',
    headline: 'Unlimited Transactions With Zero Fees',
    description: 'Get access to our exclusive app that allows you to send unlimited transactions without getting charged any fees',
    buttonLabel: 'Get Started',
    imgStart: false,
    img: require('../../img/svg-3.svg').default,
    alt: 'Car',
    dark: true,
    primary: true,
    darkText: false
};