import React, {useState} from 'react';
import Video from '../../videos/video.mp4';
import { HeroContainer, 
    HeroBg, 
    VideoBg, 
    HeroContent, 
    HeroH1, 
    HeroP, 
    HeroBtnWrapper, 
    ArrowForward, 
    ArrowRight} from './heroelements' ;
import { Button } from '../buttonelements';

const HeroSection = () => {
    const [hover, setHover] = useState(false);

    const onHover = () => {
        setHover(!hover)
    };

  return (
    <HeroContainer id="home">
        <HeroBg>
            <VideoBg autoPlay loop muted src={Video} type='video/mp4'/>
        </HeroBg>
        <HeroContent>
            <HeroH1>Hello, Welcome to my website</HeroH1>
            <HeroP>
                Contact Me Today!
            </HeroP>
            <HeroBtnWrapper>
                <Button onMouseEnter={onHover} onMouseLeave={onHover} to='contactme' smooth={true} duration={500} spy={true} exact='true' offset={-80}>
                    Contact Me {hover ? <ArrowForward />: <ArrowRight />}
                </Button>
            </HeroBtnWrapper>
        </HeroContent>
    </HeroContainer>
  )
};

export default HeroSection;